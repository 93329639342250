import React from "react";
import "./Pages.css";

export default function Coming() {
  return (
    <div id="description" className="basic-1 backgroundTexture">
      <div className="container-fluid" style={{ width: "90%" }}>
        <div
          style={{
            paddingTop: "0",
          }}
          className="row services-row"
        >
          <div className="col-lg-3">
            <img
              className="serv-img-1"
              src="images/service-img-1.png"
              alt="image"
            />
          </div>
          <div className="col-lg-9">
            <p className="rp-services" style={{ float: "left" }}>
              <h4>Job placement-Non Technical & Technical (India & Abroad)</h4>
              <br />Whether you're an experienced professional or looking for your first job, our approach to recruitment prioritizes candidates. We take the time to listen, advise and work more than anyone else to find your ideal job. <br /><br />We work with a wide range of public and private sector companies of all kinds and sizes. This makes us a great partner for you too. whatever your goals are, we're sure to know someone who can help.
            </p>
          </div>
        </div>
        <div className="tab-content" className="row services-row">
          <div className="col-lg-12">
            <img
              className="serv-img-2"
              style={{ float: "right", paddingLeft: "20px" }}
              src="images/service-img-2.png"
              alt="image"
            />
            <p className="p-services">
              <h4>Internship Programs (India)</h4>
              <br />In several professions, prior experience is required to obtain employment. Internships can help you obtain transferrable skills, experience, and knowledge while working in a real-world setting. In addition, they can provide short-term networking and sector exploration possibilities, strengthening your CV and increasing your chances of landing the job you actually desire.
            </p>
          </div>
        </div>
        <div className="row services-row">
          <div className="col-lg-4">
            <img
              style={{ float: "left" }}
              src="images/10.jpg"
              alt=""
              className="serv-img-1 ser-img1"
            />
          </div>{" "}
          <div className="col-lg-8">
            <p className="rp-services">
              <h4>
                Admissions ( Post Graduate /Professional Courses -India & Abroad)
              </h4>
              <br />We assist you in getting admission to universities that best suit your personal, academic, and financial profile/parameters, including but not limited to those we represent. We focus on your application, emphasizing the critical areas for a well-presented, error-free application. References and the 'all-important' Statement of Purpose are two areas to help.
            </p>
          </div>
        </div>
        <div
          style={{
            paddingBottom: "0",
          }}
          className="row services-row "
        >
          <div className="col-lg-12">
            <img
              style={{ float: "right" }}
              src="images/service-img-4.png"
              alt="image"
              className="serv-img-2"
            />
            <p className="p-services">
              <h4>Career / Educational Counselling</h4>
              <br />Everyone requires the assistance of a guide. A guide will help you manage the choices and problems you'll face along the route, whether you're stuck in your job, planning a career break, entering the field, or exploring your possibilities. We've put together a collection of information, tools, and resources to assist you in achieving your career objectives, no matter what they are or where you are in your career, begin exploring right now.
            </p>
          </div>
        </div>
      </div>
    </div> /* dfgds */
  );
}
