import React from "react";
import "./Pages.css";

export default function Contact() {
  return (
    <div className='backgroundTexture'>
      <div
        id="description"
        className="container basic-1 backgroundTexture"
      >
        <div className="row">
          <div
            className="col-lg-12"
            style={{ padding: "30px", paddingTop: "0px" }}
          >
            <h3 style={{ color: "#B91646" }}>
              <i
                style={{ color: "#B91646" }}
                className="fas fa-address-book contactMain-i"
              ></i>
              Contact us
            </h3>
            <p style={{ paddingLeft: "58px", color: 'black' }}>
              Request talent or companies to contact our recruitment agency to discuss your specific requirements and how we can assist you in putting together a successful team.<br /><br />

              For any questions about CareerCraftors.com, contact our team by sending an email directly or by filling in the below contact form.

            </p>
          </div>
        </div>

        {/* section for google forms  start*/}
        {/* <div aty/>Click to Submit the Form</div> */}
        <div className="row" style={{ marginTop: '-5rem' }}>
          <div className="col-lg-4 col-sm-12 form-contact-column">
            <div className="card ch-1">
              <div className="card-body" style={{ boxShadow: '1px 1px 11px 3px #d5d5d5' }}>
                <button type="button" class="btn">
                  {" "}
                  <a href="https://forms.gle/fZwXmWsRjv24XQQh6" target="_blank" rel='noreferrer'>
                    <i
                      style={{ color: "#1c5473" }}
                      class="fas fa-laptop contactMain-i "
                    ></i>
                    <h6 className="form-title-contact" style={{ color: '#f60' }}>Working Professionals</h6>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 form-contact-column">
            <div className="card ch-1">
              <div style={{ paddingRight: "10px" }} className="card-body " style={{ boxShadow: '1px 1px 11px 3px #d5d5d5' }}>
                <button type="button" class="btn ">
                  <a href="https://forms.gle/JQTQ1jcG7WYLmfdA6" target="_blank">
                    {" "}
                    <i
                      style={{ color: "#141010" }}
                      class="fas fa-graduation-cap  contactMain-i "
                    ></i>
                    <h6 className="form-title-contact" style={{ color: '#f60', width: '128%', marginLeft: '-1.8rem' }}>
                      Students (Interns / Admissions)
                    </h6>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 form-contact-column">
            <div className="card ch-1">
              <div className="card-body" style={{ boxShadow: '1px 1px 11px 3px #d5d5d5' }}>
                <button type="button" class="btn  ">
                  <a href="https://forms.gle/J4ULY9AzEDw3haFa9" target="_blank">
                    <i
                      style={{ color: "#214d27" }}
                      class="fas fa-briefcase-medical contactMain-i "
                    ></i>
                    <h6 className="form-title-contact" style={{ color: '#f60' }}>Medical Professionals</h6>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* personal-contact  */}
        <div className="row">
          <div className="col-lg-6">
            <h5
              style={{
                paddingBottom: "20px",
                paddingLeft: "23px",
                color: "#B91646",
              }}
            >
              {" "}
              <i style={{ paddingRight: "7px" }} class="fas fa-info-circle"></i>
              Contact Info
            </h5>
            <ul className="li-space-lg p-small" style={{ color: 'black', fontSize: '16px', paddingLeft: '50px' }}>
              <h4 style={{ fontSize: '16px' }}>Registered Office</h4>
              <li className="media" style={{ marginLeft: '1.7rem' }}>
                <i class="fas fa-map-marker-alt contact-details-icon"></i>
                <div className="media-body" style={{ paddingLeft: '5px' }}>
                  8/16, Marayapuram, Kappucaud, Kanyakumari - 629 162
                </div>
              </li>
              <h4 style={{ fontSize: '16px', }}>Branch Office</h4>
              <li className="media" style={{ marginLeft: '1.7rem' }}>
                <i class="fas fa-map-marker-alt contact-details-icon"></i>
                <div className="media-body" style={{ paddingLeft: '5px' }}>
                  Bangalore
                </div>
              </li>
              <li className="media" style={{ marginTop: '2rem' }}>
                <i className="fas fa-envelope contact-details-icon" />
                <div className="media-body">info@careercraftors.com</div>
                {/* <div className="media-body">info@careercraftors.com</div> */}
              </li>
              <li className="media">
                <i className="fas fa-envelope contact-details-icon" />
                <div className="media-body">careercraftors@gmail.com</div>
                {/* <div className="media-body">info@careercraftors.com</div> */}
              </li>
              <li className="media">
                <i className="fas fa-phone-alt contact-details-icon" />
                <div className="media-body">+91 78100 83072</div>
              </li>
              <li className="media">
                <i className="fas fa-phone-alt contact-details-icon" />
                <div className="media-body">+91 95388 50406</div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 ">
            <img
              className="img-fluid"
              src="images/contact-image.png"
              alt="alternative"
            />
          </div>{" "}
        </div>

      </div>
    </div>
  );
}
